import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import YoutubePlayer from "./YoutubePlayer";
import VimeoPlayer from "./VimeoPlayer";

export default function Blog() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-indigo-600">
          Exciting News
        </p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          We Won 2nd Place in the DeFi Contest!
        </h1>
        <p className="mt-6 text-xl leading-8">
          We are thrilled to announce that our team secured the second place in
          the recent DeFi contest with our innovative project, Teller - a CLI
          tool designed for the Stacks blockchain.
        </p>
        <YoutubePlayer videoId="0lNsYMT6cAs" start={506} />
        <div className="mt-10 max-w-2xl">
          <p>
            Teller is crafted to enhance the developer experience, providing
            seamless interactions with the Stacks blockchain. Our tool
            facilitates various blockchain operations, making it easier and more
            efficient for developers to build and test their applications.
          </p>
          <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  User-Friendly Interface.
                </strong>{" "}
                Designed to be intuitive and accessible for all developers,
                regardless of their experience with blockchain technology.
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Efficiency.
                </strong>{" "}
                Streamlines processes to enhance productivity and reduce time
                spent on setup and configuration.
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Innovation.
                </strong>{" "}
                Incorporates the latest advancements in blockchain technology to
                offer a cutting-edge tool.
              </span>
            </li>
          </ul>
          <p className="mt-8">
            Our journey in the contest was challenging yet rewarding, and we are
            proud of what we have accomplished. We believe Teller will play a
            crucial role in the growth of the Stacks ecosystem and beyond.
          </p>
          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            From Concept to Reality
          </h2>

          <p className="mt-6">
            The development of Teller was driven by our commitment to improving
            blockchain accessibility and usability. Our team worked tirelessly
            to turn this concept into a reality, and the recognition at the DeFi
            contest validates our efforts and vision.
          </p>
          <VimeoPlayer videoId="926964194" />

          <figure className="mt-10 border-l border-indigo-600 pl-9">
            <blockquote className="font-semibold text-gray-900">
              <p>
                “This recognition is not just a win for our team but for the
                entire Stacks community. We are excited to see how Teller will
                transform the blockchain landscape.”
              </p>
            </blockquote>
            <figcaption className="mt-6 flex gap-x-4">
              <img
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
                src="/img/headshot5.jpeg"
                alt=""
              />
              <div className="text-sm leading-6">
                <strong className="font-semibold text-gray-900">David</strong> –
                Degen
              </div>
            </figcaption>
          </figure>
          <p className="mt-10">
            We are committed to continuing our work on Teller, ensuring it
            remains at the forefront of technological innovation and serves as a
            valuable tool for developers around the world.
          </p>
        </div>
      </div>
    </div>
  );
}
