import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./components/Main";
import AboutUs from "./components/AboutUs";
import Blog from "./components/Blog";
import NotFound from "./components/NotFound";

function App() {
  return (
    <HashRouter>
      <div
        style={{ background: "url(img/bg.jpg) no-repeat center center fixed" }}
      >
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/blog" element={<Blog />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
