import YouTube, { YouTubeProps } from "react-youtube";

interface YoutubePlayerProps {
  height?: string;
  width?: string;
  videoId: string; // Required prop
  start?: number;
}

export default function YoutubePlayer({
  height = "432",
  width = "768",
  videoId,
  start = 0,
}: YoutubePlayerProps) {
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: height,
    width: width,
    playerVars: {
      autoplay: 1,
      start: start,
    },
  };

  return (
    <YouTube
      iframeClassName="aspect-video rounded-xl"
      videoId={videoId}
      opts={opts}
      onReady={onPlayerReady}
    />
  );
}
