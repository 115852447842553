import {
  AdjustmentsHorizontalIcon,
  ArrowsRightLeftIcon,
  WalletIcon,
} from "@heroicons/react/24/solid";

const features = [
  {
    name: "Contracts Interaction.",
    description:
      "Provides detailed commands for deploying, querying, and interacting with smart contracts on the STX blockchain.",
    icon: ArrowsRightLeftIcon,
  },
  {
    name: "Token Management.",
    description:
      "Facilitates the management and transfer of tokens, enhancing token operations within the STX ecosystem.",
    icon: AdjustmentsHorizontalIcon,
  },
  {
    name: "Wallet Operations.",
    description:
      "Supports wallet creation, transactions, and balance checks, providing essential tools for STX wallet management.",
    icon: WalletIcon,
  },
];

export default function Example() {
  return (
    <div id="teller" className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Accelerate Development
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Streamlined Workflow
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Teller, a cutting-edge Command Line Interface (CLI), offers
                developers rapid, streamlined access to STX blockchain data.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
              <p className="mt-5">
                <a
                  key="tellergithub"
                  href="https://github.com/hashhavoc/teller"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Github</span>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className="h-10 w-10"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </p>
            </div>
          </div>
          <img
            src="img/teller-cli.png"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
