import Barfstack from "./Barfstack";
import Teller from "./Teller";

export default function Main() {
  return (
    <div>
      <Teller />
      <Barfstack />
    </div>
  );
}
