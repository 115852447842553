import Vimeo from "@u-wave/react-vimeo";

interface VimeoPlayerProps {
  height?: string;
  width?: string;
  videoId: string; // Required prop
}

export default function VimeoPlayer({
  height = "432",
  width = "768",
  videoId,
}: VimeoPlayerProps) {
  return (
    <Vimeo
      video={videoId}
      height={height}
      width={width}
      className="aspect-video rounded-xl"
    />
  );
}
